import React from 'react';
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';
import { useHistory } from 'react-router';

import { CustomButton } from '@buttons/CustomButton';
import driver from '@assets/images/driver.png';
import womanDriver from '@assets/images/mani-error.png';
import { ZustandContext } from '@store/modalStore';
import { ModalHelp } from '@modals/ModalNeedHelp/ModalHelp';
import envelop from '@assets/images/Envelop.svg';
import phone from '@assets/images/phone.svg';
import whatsapp from '@assets/images/whatsapp.svg';

export const ErrorMantenimiento: React.FC = () => {
  const { breakpoints, palette } = useTheme();
  const { show, hide } = React.useContext(ZustandContext);

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  const history = useHistory();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          paddingTop: 7,
          flexDirection: isTablet ? 'column' : 'row',
        }}
      >
        <Box
          component={'img'}
          src={womanDriver}
          sx={{
            width: isTablet ? 350 : 458,
          }}
          alt="Error"
          style={{ objectFit: 'contain' }}
        />
        <Box sx={{ width: isTablet ? 364 : 664 , marginLeft: isTablet ? 0 : 4 }}>
          <Typography
            sx={{ fontWeight: 700, fontSize: 32, textAlign: 'center' }}
          >
            Estamos trabajando en mejoras para seguir protegiéndote
          </Typography>
          <Typography
            sx={{
              fontSize: 20,
              textAlign: 'center',
              marginTop: 5,
              marginBottom: 7,
            }}
          >
            Te invitamos a realizar tus consultas y solicitudes de cotización y emisión de seguros de tu auto, moto y pick-up enviando los siguientes datos de contacto al correo solicitudescoppel@inter.mx.

          </Typography>
          <Typography
            sx={{
              fontSize: 20,
              textAlign: 'center',
              marginTop: 5,
              marginBottom: 7,
              fontWeight: 'bold',

            }}
          >

            Nombre:

            <br /><br />Número de empleado:

            <br /><br />Teléfono de contacto:
          </Typography>
          <Typography
            sx={{
              fontSize: 20,
              textAlign: 'center',
              marginTop: 5,
              marginBottom: 7,
            }}
          >
            Nuestro equipo se pondrá en contacto contigo lo antes posible para atender tu petición.

            <br /><br />¡Gracias por tu comprensión!
          </Typography>
        </Box>
      </Box>
      <Box style={{marginTop: '39.89px'}} />
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ flexDirection: isTablet ? 'column' : 'row' }}
      >
        <CustomButton
          text="solicitudescoppel@inter.mx"
          onClick={() => null}
          size={isDesktop ? 'medium' : 'small'}
          variant={'text'}
          startIcon={
            <Box
              component={'img'}
              src={envelop}
              sx={{
                width: '24px',
                height: '24px',
              }}
              alt="Phone"
            />
          }
          style={{
            color: palette.primary.main,
            fontSize: isMobile ? '0.64rem' : '15px',
            textTransform: 'none',
            marginBottom: '24px',
            marginTop: '24px',
          }}
        />
      </Box>
    </Box>
  );
};
