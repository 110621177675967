interface Producto {
  name: string;
  url: string;
}

export const productos: Producto[] = [
  { name: 'Médico Seguro', url: 'https://medicoseguro.inter.mx/inicio' },
  {
    name: 'Mascota Segura',
    url: 'https://mascotasegura.inter.mx/seguro-mascota/landing',
  },
  { name: 'Bien Seguro', url: 'https://productos.inter.mx/bien-seguro/' },
  { name: 'Viaje Seguro', url: 'https://vigo.inter.mx/landing' },
];

export const tmpProductos: Producto[] = [
  {
    name: 'Mascota Segura',
    url: 'https://mascotasegura.inter.mx/seguro-mascota/landing',
  },
  { name: 'Gasto Médico Mayor', url: 'https://vigo.inter.mx/landing' },
];

export const contactanos: string[] = ['solicitudescoppel@inter.mx'];
export const clientes: Producto[] = [
  { name: 'Login', url: 'https://www.interbi.mx/qlikview/FormLogin.htm' },
  { name: 'Aviso de privacidad', url: 'https://inter.mx/aviso-de-privacidad' },
];
