import React from 'react';
import { Clear } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import {
  Modal,
  Box,
  Paper,
  Typography,
  IconButton,
  Backdrop,
  Fade,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { useStyles } from './useStyles';
import { CustomButton } from '@buttons/CustomButton';
import envelop from '@assets/images/Envelop.svg';
import backarrow from '@assets/images/BackArrow.svg';
import { ZustandContext } from '@store/modalStore';
import { ModalContact } from './ModalContact';

type ModalHomeProps = {
  onClose: () => void;
};

export const ModalHelp: React.FC<ModalHomeProps> = ({ onClose }) => {
  const classes = useStyles();
  const { breakpoints, palette } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const { show, hide } = React.useContext(ZustandContext);
  const history = useHistory();

  const pages = {
    options: '/options',
    principal: '/',
  };

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root} style={{ height: '390px' }}>
          <Box padding={2}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              textAlign="center"
              position={'relative'}
            >
              <Typography
                variant={'h6'}
                fontWeight={'bold'}
                marginLeft="5px"
                paddingTop="25px"
                marginBottom="20px"
              >
                ¿No encuentras tu vehículo?
              </Typography>
              <Box position={'absolute'} top={-5} right={0}>
                <IconButton onClick={onClose}>
                  <Clear />
                </IconButton>
              </Box>
            </Box>
            <Typography fontWeight={700} textAlign={'center'} fontSize={16}>
              Escríbenos al correo
            </Typography>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Box
                component={'img'}
                src={envelop}
                sx={{
                  width: 24,
                  height: 24,
                }}
                alt="Phone"
              />
              <CustomButton
                text="solicitudescoppel@inter.mx"
                onClick={() => null}
                size={isDesktop ? 'medium' : 'small'}
                variant={'text'}
                style={{
                  color: palette.primary.main,
                  fontSize: '14px',
                  textTransform: 'none',
                  textDecoration: 'underline',
                  marginBottom: '24px',
                  marginTop: '24px',
                }}
              />
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
